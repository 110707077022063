


































import BaseCard from "@/components/Base/BaseCard.vue";
import BaseForm from "@/components/Base/BaseForm.vue";
import BaseWrapper from "@/components/Base/BaseWrapper.vue";
import { toNumber } from "@/helpers/Formatter";
import { Component, Vue } from "vue-property-decorator";

@Component({
    components: {
        BaseWrapper,
        BaseForm,
        BaseCard,
    },
})
export default class AppPlanNew extends Vue {
    get forms() {
        let forms: any[] = [
            {
                text: "Tahun",
                placeholder: "Rencana untuk tahun",
                type: "number",
                value: "year",
                min: 2021,
                withLabel: true,
            },
            {
                text: "Dana",
                placeholder: "Anggaran dana CSR",
                type: "currency",
                value: "fund",
                prefix: "Rp",
                withLabel: true,
            },
            {
                text: "Kegiatan",
                placeholder: "Rencana kegiatan",
                type: "array",
                value: "events",
                buttonText: "Tambah kegiatan",
                withLabel: true,
            },
        ];

        return forms;
    }

    public forceUpdateCount: number = 0;

    public formData: any = {};

    forceUpdate() {
        this.forceUpdateCount++;
    }

    async submit() {
        this.formData.fund = toNumber(this.formData.fund);

        const response = await this.$store.dispatch(
            "plans/addPlan",
            this.formData
        );

        if (!response.errors.length) {
            this.$store.commit("meta/SET_PAGE_TRANSITION", "back");
            this.$router.push("/app/rencana");
        }
    }
}
